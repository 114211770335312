const BASIC = process.env.VUE_APP_BASIC_PRICE_ID;
const PROFESSIONAL = process.env.VUE_APP_PROFESSIONAL_PRICE_ID;
const ELITE = process.env.VUE_APP_ELITE_PRICE_ID;

export default 
[
  {
    "id": "0000-0000-000-00000-00000",
    "title": "Free",
    "color": "rgb(246 211 129)",
    "color_transparent": "rgb(246, 211, 129, 0.33)",
    "features": {
      rts: true,
      query: "Limited",
      rps: 1,
      rpd: 10,
      rpm: 100,
      seats: 1,
      support: undefined,
      sla: undefined,

      ip_info: true,
      domain_info: true,
      subdomains: "Max 200 results",
      rdns: "Max 200 results",
      similar_domains: "Max 200 results",
      emails: "Max 200 results",
      netflow: undefined,

      ip_block: undefined,
      domain_block: undefined,
      country: undefined,
      city: undefined,
      org: undefined,
      anon: undefined,
      threats: undefined,
      fdns: undefined,
      new_domains: undefined
    },
    "price_id": "",
    "caption": "This is where you start, and try things out",
    "price_caption": "",
    "purchasable": false,

    "price": "Free",
    "frequency": "",
  
    "contact_buy": false,
    "account_type": 1,

    "restriction": "Individual use only",
  },
  {
    "id": "0000-0000-000-00000-00000",
    "title": "Basic",
    "color": "rgb(246 129 237)",
    "color_transparent": "rgb(246, 129, 237, 0.33)",
    "features": {
      rts: true,
      query: "Limited",
      rps: 5,
      rpd: "2k",
      rpm: "10k",
      seats: 1,
      support: "Documentation",
      sla: undefined,

      ip_info: true,
      domain_info: true,
      subdomains: true,
      rdns: "Max 200 results",
      similar_domains: true,
      emails: "Max 200 results",
      netflow: undefined,

      ip_block: true,
      domain_block: true,
      country: undefined,
      city: undefined,
      org: undefined,
      anon: undefined,
      threats: undefined,
      fdns: undefined,
      new_domains: undefined
    },
    "price_id": BASIC,
    "caption": "For small teams and startups",
    "price_caption": "10k api calls",
    "purchasable": true,

    "price": "$19",
    "frequency": "/month",

    "account_type": 2,

    "restriction": "Individual use only",
  },
  {
    "id": "0000-0000-000-00000-00000",
    "title": "Professional",
    "color": "rgb(141 129 246)",
    "color_transparent": "rgb(141, 129, 246, 0.33)",
    "features": {
      rts: true,
      query: true,
      rps: 20,
      rpd: "10k",
      rpm: "100k",
      seats: 1,
      support: "Chat + Email",
      sla: undefined,

      ip_info: true,
      domain_info: true,
      subdomains: true,
      rdns: true,
      similar_domains: true,
      emails: "Max 200 results",
      netflow: undefined,

      ip_block: true,
      domain_block: true,
      country: true,
      city: undefined,
      org: undefined,
      anon: undefined,
      threats: undefined,
      fdns: undefined,
      new_domains: undefined
    },
    "purchasable": true,
    "price_id": PROFESSIONAL,
    "caption": "For growing businesses",
    "price_caption": "100k api calls & new endpoints",

    "price": "$99",
    "frequency": "/month",

    "account_type": 3,

    "restriction": "Individual use only",
  },
  {
    "id": "0000-0000-000-00000-00000",
    "title": "Elite",
    "color": "rgb(129 186 246)",
    "color_transparent": "rgb(129, 186, 246, 0.33)",
    "features": {
      rts: true,
      query: true,
      rps: "Unlimited",
      rpd: "20k",
      rpm: "200k",
      seats: 1,
      support: "Dedicated Slack",
      sla: undefined,

      ip_info: true,
      domain_info: true,
      subdomains: true,
      rdns: true,
      similar_domains: true,
      emails: true,
      fingerprint: true,
      netflow: undefined,

      ip_block: true,
      domain_block: true,
      country: true,
      city: true,
      org: true,
      anon: undefined,
      threats: undefined,
      fdns: undefined,
      new_domains: undefined
    },
    "price_id": ELITE,
    "caption": "For large organizations",
    "price_caption": "200k api calls & datasets",
    "purchasable": true,

    "price": "$499",
    "frequency": "/month",

    "account_type": 4,

    "restriction": "Individual use only",
  },
  {
    "id": "0000-0000-000-00000-00000",
    "title": "Enterprise",
    "color": "#FFFFFF",
    "color_transparent": "rgb(255, 255, 255, 0.33)",
    "features": {
      rts: true,
      query: true,
      rps: "Unlimited",
      rpd: "Unlimited",
      rpm: "Unlimited",
      seats: "Unlimited",
      support: "Phone",
      sla: true,

      ip_info: true,
      domain_info: true,
      subdomains: true,
      rdns: true,
      similar_domains: true,
      emails: true,
      fingerprint: true,
      netflow: true,

      ip_block: true,
      domain_block: true,
      country: true,
      city: true,
      org: true,
      anon: true,
      threats: true,
      fdns: true,
      new_domains: true
    },
    "price_id": "price_1QCiJhKWiwNNrZCKRwcTASde",
    "caption": "For global enterprises and critical operations",
    "price_caption": "starting from $5,000/month",
    "contact_buy": true,
    "purchasable": true,

    "price": "Custom",
    "frequency": "",

    "account_type": 5,

    "restriction": "Enterprise & Commercial use",
  }
  ]
