<script setup>
  import { ref, onMounted } from 'vue'
  import Plans from '@/data/plans.js'
  import ContactModal from '@/components/modals/Contact.vue'
  import Payment from '@/api/Payment.js'
  import moment from 'moment'

  import { useUserStore } from '@/stores/user'
  let userStore = useUserStore()
  import { useMainStore } from '@/stores/main'
  const mainStore = useMainStore()

  const basic_loading = ref(false)
  const pro_loading = ref(false)
  const elite_loading = ref(false)
  const loading = ref(false)

  function startLoadAnimation(plan_name) {
    if (plan_name == "Basic") basic_loading.value = true
    else if (plan_name == "Professional") basic_loading.value = true
    else if (plan_name == "Elite") basic_loading.value = true
  }

  const planNames = {
    1: 'Free',
    2: 'Basic',
    3: 'Professional',
    4: 'Elite',
    5: 'Enterprise'
  }

  // If we try to update the status immediately, the webhook might not have been processed yet
  // if we wait a second, it will almost certainly have.
  async function cancel_subscription() {
    let session = await Payment.cancel_subscription()
    window.location.href = session.url
  }

  async function renew_subscription() {
    let session = await Payment.renew_subscription()
    await new Promise(resolve => setTimeout(resolve, 1000));
    await userStore.details()
  }

  async function update_paymentinfo() {
    let session = await Payment.customer_portal("/account/billing")
    window.location.href = session.url
  }

  const card = ref(null)

  onMounted(async () => {
    card.value = (await Payment.payment_method()).data;
  })

</script>

<template>
  <div id="pj">
    <div class="d-flex vertically-centered">
      <div>
        <h3 class="m-0">{{ planNames[userStore.accountType] }}</h3>
        <p v-if="userStore.accountType > 1" class="mb-0 mt-2 text-muted">
          {{ userStore.subscription_canceled ? 
            'Plan expires' : 
            'Plan renews' }} on {{ moment(userStore.subscription_period_end).format('MMMM D, YYYY') }}
        </p>
      </div>
      <div class="ms-auto d-flex">
        <span v-if="userStore.accountType > 1 && !userStore.subscription_canceled" 
                class="button black me-2" 
                @click="cancel_subscription">
          Cancel Subscription
        </span>
        <span v-if="userStore.accountType > 1 && userStore.subscription_canceled"
                class="button green me-2"
                @click="renew_subscription">
          Renew Subscription
        </span>
        <router-link v-if="userStore.accountType < 5" to="/plans"
                class="button" 
                >
          Change plan
        </router-link>
      </div>
    </div>

    <div v-if="card && card.brand">
      <hr>
      <div class="d-flex">
        <div>
          <div class="d-flex">
            <div class="mb-1">
              <img v-if="card.brand == 'visa'" src="@/assets/icons/payment/visa.png" class="cc me-2"/>
              <img v-else-if="card.brand == 'mastercard'" src="@/assets/icons/payment/mastercard.png" class="cc me-2"/>
              <span v-else class="me-2">{{ card.brand }}</span>
            </div>
            <span class="white">*** *** *** {{ card.last4 }}</span>
          </div>
          <div>
            <span class="small">{{ card.exp_month}}/{{ card.exp_year }}</span>
          </div>
        </div>
        <div>
          <span class="badge text-bg-danger ms-2" v-if="userStore.subscription_status == 'past_due'">
            PAYMENT FAILED
          </span>
        </div>
        <div class="ms-auto">
           <a style="color: cornflowerblue; text-decoration: underline; cursor: pointer;" @click="update_paymentinfo()">Update payment information</a>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
#pj {
    background-color: #1B2029;
    border: 1px solid #232A35;
    border-radius: 20px;
    box-shadow: 2px 4px 7px 0px #00000017;
    padding: 2rem;
}

.cc {
  height: 18px;
}

.price {
  font-size: 2rem;
  color: white;
  font-weight: 800;
  line-height: 2rem;
}

.caption {
  line-height: 1.3rem;
  height: 2.6rem;
}


.elem {
  flex: 1;
  padding: 2rem;
}

.elem:not(:last-child) {
  border-right: 1px solid #232a3582
}

.title {
  font-family: Roboto;
  font-weight: 600;
  color: white;
  font-size: 1rem;
}


.button {
  display: block;
  padding: .5rem 1.1rem;
  border-radius: 10px;
  background-color: #5094ed;
  color: white;
}

.button:hover {
  background-color: #4c82c8;
  cursor: pointer;
}

.button.black {
  background-color: #232A35;
}

.button.black:hover {
  background-color: #2F3744;
}

.button.green {
  background-color: #65bc65;
}

.button.green:hover {
  background-color: #549f54;
}

.button.current {
  background-color: transparent;
  border-style: inset;
  cursor: default;
  border: 1px solid #232A35;
}

</style>
