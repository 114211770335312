<script setup>
  import { ref } from 'vue'
  import Plans from '@/data/plans.js'
  import ContactModal from '@/components/modals/Contact.vue'
  import Payment from '@/api/Payment.js'

  import { useUserStore } from '@/stores/user'
  let userStore = useUserStore()
  import { useMainStore } from '@/stores/main'
  const mainStore = useMainStore()

  const basic_loading = ref(false)
  const pro_loading = ref(false)
  const elite_loading = ref(false)
  const loading = ref(false)

  async function purchase_plan(price_id, plan_name) {
    if(loading.value == true) return 
    startLoadAnimation(plan_name) 

    let session = await Payment.create_session(price_id)
    window.location.href = session.url
  }

  async function change_subscription(price_id, plan_name) {
    if(loading.value == true) return 
    startLoadAnimation(plan_name) 

    let session = await Payment.change_subscription(price_id)
    window.location.href = session.url
  }

  function startLoadAnimation(plan_name) {
    if (plan_name == "Basic") basic_loading.value = true
    else if (plan_name == "Professional") basic_loading.value = true
    else if (plan_name == "Elite") basic_loading.value = true
  }

  //mainStore.show_modal(qf, { fileName: file.name, domains: res.domains, ips: res.ips})
  function contact() {
    mainStore.show_modal(ContactModal, { sender: userStore.email, name: userStore.name, topic: "Enterprise Plan" }) 
  }
  
  let plans = Plans.filter( p => p.purchasable )
</script>

<template>
  <div id="pj">

    <div v-for="p in plans" :key="p" class="elem">
      <h5 class="title uppercase mb-3" :style="{'color': p.color}">{{ p.title }}</h5>
      <p class="caption">{{ p.caption }}</p>

      <div class="mb-4">
        <div>
          <span class="price">{{ p.price }}</span>
          <span>{{ p.frequency }}</span>
        </div>
        <span class="small">{{ p.price_caption}}</span>
      </div>
        
      <div v-if="userStore.loggedIn" class="mb-3">
        <span class="button me-2 text-center black" @click="contact()" v-if="p.contact_buy">Contact</span>
        <span class="button text-center me-2" @click="purchase_plan(p.price_id, p.Title)" v-else-if="userStore.accountType == 1">Buy</span>
        <span class="button text-center current me-2" v-else-if="userStore.accountType == p.account_type">
          <span class="me-2">Your current plan</span>
          <font-awesome-icon style="font-size: 1rem; color: #58ff58;" :icon="['fas', 'check']" />

        </span>
        <span class="button text-center me-2" @click="change_subscription(p.price_id, p.Title)" v-else-if="userStore.accountType < p.account_type">Upgrade</span>
        <span class="button text-center me-2" @click="change_subscription(p.price_id, p.Title)" v-else-if="userStore.accountType > p.account_type">Downgrade</span>


        <span class="button text-center me-2" v-else to="/signup">Get started</span>
      </div>
      <div v-else class="mb-3">
        <router-link class="button me-2 text-center black" to="/about" v-if="p.contact_buy">Contact</router-link>
        <router-link class="button text-center me-2" v-else to="/signup">Get started</router-link>
      </div>



      <div class="text-center small">
        {{ p.restriction }}
      </div>
    </div>
  </div>
</template>

<style scoped>
#pj {
    background-color: #1B2029;
    border: 1px solid #232A35;
    border-radius: 20px;
    box-shadow: 2px 4px 7px 0px #00000017;
    display: flex;
}

.price {
  font-size: 2rem;
  color: white;
  font-weight: 800;
  line-height: 2rem;
}

.caption {
  line-height: 1.3rem;
  height: 2.6rem;
}


.elem {
  flex: 1;
  padding: 2rem;
}

.elem:not(:last-child) {
  border-right: 1px solid #232a3582
}

.title {
  font-family: Roboto;
  font-weight: 600;
  color: white;
  font-size: 1rem;
}


.button {
  display: block;
  padding: .5rem 1.1rem;
  border-radius: 10px;
  background-color: #5094ed;
  color: white;
}

.button:hover {
  background-color: #4c82c8;
  cursor: pointer;
}

.button.black {
  background-color: #232A35;
}

.button.black:hover {
  background-color: #2F3744;
}

.button.current {
  background-color: transparent;
  border-style: inset;
  cursor: default;
  border: 1px solid #232A35;
}

@media (max-width: 600px) {
  #pj {
    flex-direction: column;
    
  }

  .elem:not(:last-child) {
    border-right: none;
    border-bottom: 1px solid #232a3582;
  }
  
}
</style>

