<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { useMainStore } from '@/stores/main'

const userStore = useUserStore()
const mainStore = useMainStore()
const router = useRouter()

async function logout() {
    await userStore.logout()
    return router.push({ path:"/" })
}

onMounted(async () => {

})

// sleep time expects milliseconds
function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}


// submenus
// components - access html through .$el
const dashboard = ref(null);
const resources = ref(null);
const home = ref(null);
// html elements - can access directly
const account = ref(null);
const services = ref(null);

</script>

<template>
   <nav id="mainnav" class="navbar navbar-expand-lg navbar-dark" ref="mainnav">
    <div class="container pn">

      <router-link class="navbar-brand d-lg-none" to="/">
        <img src="~@/assets/webscout.svg" alt="" width="130">
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mb-lg-0">
            <li class="nav-item ps-0">
                <router-link class="line navbar-brand d-none d-lg-block ps-0 ms-0 no-margin logo-placement" ref="home" to="/">
                    <img src="~@/assets/webscout.svg" alt="" ref="logoref" width="130">
                </router-link>
            </li>

            <li class="nav-item">
                <a class="nav-link" ref="dashboard" href="https://blog.webscout.io" aria-current="page">
                  Blog
                  <div v-show="mainStore.nav_needhelp" v-html="mainStore.nav_needhelp" class="submenu"></div>
                </a>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" ref="services" to="/datasets" aria-current="page">
                  Datasets
                </router-link>
            </li>

            <li class="nav-item">
                <router-link class="nav-link" ref="services" to="/pricing" aria-current="page">
                  Pricing
                </router-link>
            </li>

              <li class="nav-item dropdown ">
                <span class="nav-link dropdown-toggle" ref="resources" id="resources" to="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Resources
                </span>
                
                <div class="dropdown-menu dropdown-menu-start" id="dd-resources" aria-labelledby="resources">
                  <div class="row" style="width: 562px;">
                    <div class="col-md-6 pt-3 pb-3">

                        <router-link to="/resources/privacy" class="product-item vertically-centered">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_531_509)">
                              <path d="M17.4997 3.33331H2.49967C1.5792 3.33331 0.833008 4.0795 0.833008 4.99998V15C0.833008 15.9205 1.5792 16.6666 2.49967 16.6666H17.4997C18.4201 16.6666 19.1663 15.9205 19.1663 15V4.99998C19.1663 4.0795 18.4201 3.33331 17.4997 3.33331Z" stroke="#3A495A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M0.833008 8.33331H19.1663" stroke="#3A495A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              </g>
                              <defs>
                              <clipPath id="clip0_531_509">
                              <rect width="20" height="20" fill="white"/>
                              </clipPath>
                              </defs>
                          </svg>

                          <div class="product-text">
                            <div class="product-header">Privacy Policy</div>
                          </div>
                        </router-link>
                        
                    </div>
                    <div class="col-md-6 pt-3 pb-3">

                        <router-link to="/resources/termsofservice" class="product-item vertically-centered">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.83301 9.16665L7.49967 7.49998L5.83301 5.83331" stroke="#3A495A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M9.16699 10.8333H12.5003" stroke="#3A495A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M15.8333 2.5H4.16667C3.24619 2.5 2.5 3.24619 2.5 4.16667V15.8333C2.5 16.7538 3.24619 17.5 4.16667 17.5H15.8333C16.7538 17.5 17.5 16.7538 17.5 15.8333V4.16667C17.5 3.24619 16.7538 2.5 15.8333 2.5Z" stroke="#3A495A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>

                          <div class="product-text">
                            <div class="product-header">Terms of Service</div>
                          </div>
                        </router-link>
                    </div>
                  </div>
                </div>
                
            </li>

            <li class="nav-item">
                <router-link class="nav-link" ref="services" to="/about" aria-current="page">
                  Contact
                </router-link>
            </li>
                    </ul>
        <ul class="navbar-nav mb-lg-0 ms-auto">
            <li class="nav-item">
              <router-link class="line nav-link btn btn-login vertically-centered d-flex" to="/login">
                  <span class="me-3">Log in</span>
                  <font-awesome-icon style="color: inherit; font-size: .8rem;" :icon="['fas', 'chevron-right']" />
              </router-link>
            </li>
        </ul>
      </div>


    </div>
  </nav>
</template>

<style scoped>
.btn-login {
    padding: .3rem 1.5rem !important; 
    background-color: #232A34;
    outline:none;
    box-shadow: none;
    border:none;
    color: white;
    border-radius: 30px;
    border: 1px solid #455160;
}
.btn-login:hover span {
  color: white !important;
}
.btn-login:hover {
}

.router-link-active {
  color: white !important;
}

.logo-placement {
    position: relative;
    top: -6px;
}

.logo-placement .submenu {
    bottom: -10px !important; 
    left: 0 !important; 
  }

.btn-continue {
        padding: .6rem 2rem; 
    background-color:#7357D2;
    font-weight: bold;
    outline:none;
    box-shadow: none;
    border:none;
    color: white;
    border-radius: 30px;
}

.logi {
  /*
  background-color: transparent !important; 
  background: -moz-linear-gradient(180deg, rgba(29,35,44,1) 0%, rgba(29,35,44,1) 77%, rgba(29,35,44,0.5004202364539565) 100%);
  background: -webkit-linear-gradient(180deg, rgba(29,35,44,1) 0%, rgba(29,35,44,1) 77%, rgba(29,35,44,0.5004202364539565) 100%);
  background: linear-gradient(180deg, rgba(29,35,44,1) 0%, rgba(29,35,44,1) 77%, rgba(29,35,44,0.5004202364539565) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1d232c",endColorstr="#1d232c",GradientType=1); 
  */

  position: fixed;
  z-index: 10 !important;
  border-radius: 0 0 30px 30px;
}

#mainnav {
    z-index: 2; 
    height: 64px;
    /*
    background-color: #1D232C;
    box-shadow: 0px 2px 12px #141A22;
    border: 2px solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-image-slice: 1;
    border-width: 2px;
    */
    /* border-image-source: linear-gradient(90deg, #1C232C, #1C232C 45.29%, #FFFFFF 49.82%, #1C232C 54.84%, #1C232C 99.39%); */
    /* border-image-source: linear-gradient(to left, #743ad5, #d53a9d); */
}

.no-margin {
    margin-right: 0;
}
#mainnav li {
    align-items: end;
    display: flex;
    /* line-height: 1rem; */
    padding: 0 2rem;
    /* position: relative; */
}
#mainnav li .submenu {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -9px;
  display: flex;
  justify-content: center;
  font-size: 12px;
  /* overflow: hidden;  */
  z-index: 0;
  pointer-events:none;
  color: #727D8D;
  align-items: center;
  animation-name: fadeInTop;
  animation-duration: .5s;
  animation-fill-mode: both;
}

#mainnav li .submenu.logo {
  bottom: 0px;
  left: -10px;
}

#mainnav .nav-link {
  position: relative;
}

#mainnav li a, #mainnav li span {
    /* padding-bottom: .3125rem; */
    /* font-weight: bold; */
    color: #D0D5DC;
    z-index: 1;
}

#mainnav li a:hover {
    color: white; 
}
#mainnav li span:hover {
    color: white; 
}

#mainnav li a.disabled {
    color: rgba(173, 182, 194, 0.3);
    pointer-events: none;
}

#mainnav ul .navbar-brand {
    margin: 0 2rem;
}


#mainnav .dropdown-toggle:after { content: none }


/* Dropdown */

#dd-products {
    border-radius: 20px;
    padding-bottom: 0;
    top: 50px;
    /* left: -8px; */
}

#dd-resources {
    border-radius: 20px;
    padding-bottom: 0;
    top: 50px;
}

#dd-account {
    border-radius: 20px;
    padding-bottom: 0;
    top: 50px;
}

.mmb-2 {
  margin-bottom: 2rem;
}

/* 
Add triangle to dropdown, disable when in mobile as this technique doesn't work there */
@media (min-width: 992px) {
  #mainnav {
    padding-top: 3rem;
    margin-bottom: 3rem;
  } 

  #dd-products::after {
    display: block;
    content: ' ';
    background-size: 19px 12px;
    height: 12px;
    width: 19px;
    background-image: url('~@/assets/icons/triangle.svg');
    position: absolute;
    top: -7px;
    left: 60px;
  }

  #dd-resources::after {
    display: block;
    content: ' ';
    background-size: 19px 12px;
    height: 12px;
    width: 19px;
    background-image: url('~@/assets/icons/triangle.svg');
    position: absolute;
    top: -7px;
    left: 63px;
  }
  #dd-account::after {
    display: block;
    content: ' ';
    background-size: 19px 12px;
    height: 12px;
    width: 19px;
    background-image: url('~@/assets/icons/triangle.svg');
    position: absolute;
    top: -7px;
    right: 58px;
  }
}


.product-item {
  white-space: nowrap;
  display: flex;
  padding: 1rem 3.5rem 1rem 2.5rem;
}

.product-item svg {
    margin-right: .75rem;
}

.product-item:hover:not(.disabled) .product-header::after {
  width: 19px;
}

.product-item:hover:not(.disabled) {
  cursor: pointer;
}

.product-item.disabled {
  cursor: default;
}

.product-item.disabled {
  opacity: .3;
}

.product-box {
  border-radius: 6px;
  width: 28px;
  height: 28px;
}

.product-public {
  background-color: #E780F5;
}

.product-scan {
  background-color: #7357D2;
}

.product-360 {
  background-color: #5094ED;
}

.product-text {
  display: flex;
  flex-direction: column;
  font-family: Sansation;
  color: #4C5B6F;
  position: relative;
}

#dd-account .product-text {
    justify-content: end;
    margin-bottom: .1rem;
}


.product-header {
  font-weight: bold;
  font-size: .9rem;
  line-height: .9rem;
  
}
.product-header::after {
  display: inline-block;
  content: ' ';
  background-size: 19px 12px;
  height: 12px;
  width: 19px;

  width: 0;
  position: absolute;
  top: 1px;
  background-image: url('~@/assets/icons/arrow-right.svg');
}

#dd-account .product-header::after {
    top: 5px;
}

.product-subheader {
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: #57677d;
  font-weight: 500;
}

.dd-grayed {
  display: flex;
  background-color: #F3F8FF;
  color: #66778D !important;
  font-weight: bold;
  font-family: Sansation;
  border-radius: 0 0 20px 20px;
  justify-content: center;
  padding: 1.25rem 0;
  font-size: 0.9rem;
  line-height: 0.9rem;
  list-style: none;
  text-decoration: none;
  align-items: center;
}

.dd-grayed:hover{
  cursor: pointer;
  color: #394554;
}

.dd-grayed:hover svg{
  stroke: #394554;
}
/* 
Add triangle to dropdown, whenever disable when in mobile as this technique doesn't work there */
@media (max-width: 992px) {

  #navbarSupportedContent {
    position: absolute;
    left: 1rem;
    top: 65px;
    right: 1rem;
    padding: 1rem;
    border: 1px solid white;
    border-radius: 15px 0 15px 15px;
    background-color: white;
    overflow: hidden;
    z-index:10;
  }

  #navbarSupportedContent li a, #navbarSupportedContent li span {
    color: #656E79;
  }

  #navbarSupportedContent li {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .btn-login span {
      color: white !important;
  } 
  .btn-login {
    border-radius: 5px;
  }
}


  @-webkit-keyframes fadeInTop {
  0% {
      opacity: 0;
      -webkit-transform: translateY(3px);
  }
  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
  }
  }

</style>
