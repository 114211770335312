<script setup>
import { useUserStore } from '@/stores/user'
import { useRouter, useRoute} from 'vue-router'
const userStore = useUserStore()
import { useMainStore } from '@/stores/main'
let mainStore = useMainStore()
import Payment from '../../../api/Payment'

import { onMounted,ref } from 'vue'
import Slideover from '../../../components/SlideOver/slideover.vue'
import CreateForm from '../../../components/SlideOver/forms/CreateApiKey.vue'
import User from '../../../api/User';
import moment from 'moment'
import Card from '../../../components/Card.vue'

let router = useRouter()

const keys = ref([])

onMounted(async () => {
  await userStore.details()
})

</script>

<template>
  <div class="container d-flex column pt-5">

    <div class="d-flex column mb-5">
      <h2 class="sub-title title mb-3">
        Account settings
      </h2>
    </div>


    <div class="account-nav">
      <router-link to="/account">Information</router-link>
      <router-link to="/account/apikeys">API keys</router-link>
      <router-link to="/account/billing">Plan & Billing</router-link>
    </div>

    <hr class="mb-4">


    <!-- Router view show the correct API page -->
    <router-view/>

  </div>
</template>

<style scoped>
.sidebar {
  border-left: 1px solid #1e252f;
}

.account-nav a {
  color: #6D7785;
  text-decoration: none;
  font-size: 1.1rem;
}

.account-nav a:not(:first-child) {
  margin-left: 2.5rem;
}

.list-group-item {
  background-color: #191f27;
  border-color: #1e252f;
  color: #6d7785;
}

.list-group-item:hover {
  background-color: #1c222b
}

.router-link-exact-active {
  color: white !important;
}


.btn {
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
}

.btn-danger {
  background-color: rgb(242 92 92);
  border: none;
  color: white;
}

.btn-primary {
  background-color: #3b82f6;
  border: none;
  color: white;
}

.btn:hover {
  opacity: 0.9;
}
</style>
