<script setup>
  import { ref } from 'vue'
  import Plans from '@/data/plans.js'
  import ContactModal from '@/components/modals/Contact.vue'
  import Payment from '@/api/Payment.js'
  import moment from 'moment'

  import { useUserStore } from '@/stores/user'
  let userStore = useUserStore()
  import { useMainStore } from '@/stores/main'
  const mainStore = useMainStore()

  const basic_loading = ref(false)
  const pro_loading = ref(false)
  const elite_loading = ref(false)
  const loading = ref(false)

  async function purchase_plan(price_id, plan_name) {
    if(loading.value == true) return 
    startLoadAnimation(plan_name) 

    let session = await Payment.create_session(price_id)
    window.location.href = session.url
  }

  async function change_subscription(price_id, plan_name) {
    if(loading.value == true) return 
    startLoadAnimation(plan_name) 

    let session = await Payment.change_subscription(price_id)
    window.location.href = session.url
  }

  function startLoadAnimation(plan_name) {
    if (plan_name == "Basic") basic_loading.value = true
    else if (plan_name == "Professional") basic_loading.value = true
    else if (plan_name == "Elite") basic_loading.value = true
  }

  //mainStore.show_modal(qf, { fileName: file.name, domains: res.domains, ips: res.ips})
  function contact() {
    mainStore.show_modal(ContactModal, { sender: userStore.email, name: userStore.name, topic: "Enterprise Plan" }) 
  }

  async function renew_subscription() {
    let session = await Payment.renew_subscription()
    await new Promise(resolve => setTimeout(resolve, 1000));
    await userStore.details()
  }
  
  let plans = Plans.filter( p => p.purchasable )
</script>

<template>
  <div id="pj">
    <div>
      <h5 class="title mb-3" style="color: white; font-size: 1.8rem;">We are sad to see you go</h5>
      <p class="m-0">Your plan i set to expire <span style="color: white">{{ moment(userStore.subscription_period_end).fromNow() }}</span>. To avoid losing access, you can renew your subscription, or choose a plan that better suits your needs</p>
    </div>
    <div class="ms-auto">
        <span class="button me-2 text-center green" @click="renew_subscription()" style="padding: .5rem 2rem;">Renew</span>
    </div>
  </div>
</template>

<style scoped>
#pj {
    background-color: #1B2029;
    border: 1px solid #232A35;
    border-radius: 20px;
    box-shadow: 2px 4px 7px 0px #00000017;
    display: flex;
    padding: 2rem;
    display: flex;
    align-items: center;
}

.price {
  font-size: 2rem;
  color: white;
  font-weight: 800;
  line-height: 2rem;
}

.caption {
  line-height: 1.3rem;
  height: 2.6rem;
}


.elem {
  flex: 1;
  padding: 2rem;
}

.elem:not(:last-child) {
  border-right: 1px solid #232a3582
}

.title {
  font-family: Roboto;
  font-weight: 600;
  color: white;
  font-size: 1rem;
}


.button {
  display: block;
  padding: .5rem 1.1rem;
  border-radius: 10px;
  background-color: #5094ed;
  color: white;
}

.button:hover {
  background-color: #4c82c8;
  cursor: pointer;
}

.button.black {
  background-color: #232A35;
}

.button.black:hover {
  background-color: #2F3744;
}

.button.green {
  background-color: #65bc65;
}

.button.green:hover {
  background-color: #549f54;
}

.button.current {
  background-color: transparent;
  border-style: inset;
  cursor: default;
  border: 1px solid #232A35;
}

</style>
