import { defineStore } from 'pinia'
import Auth from '../api/Auth';
import User from '../api/User';
import Search from '../api/Search';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'
//import { useDomainStore } from '@/stores/domains'
//import { useMonitorStore } from '@/stores/monitor'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      // all these properties will have their type inferred automatically
      id: '',
      email: '',
      name: '',
      loggedIn: false,
      accountType: 1,
      token: '',
      
      queries: [],

      limit_feed: 0,
      limit_api_daily: 0,
      limit_api_monthly: 0,

      feed_spend: 0,
      api_daily_spend: 0,
      api_monthly_spend: 0,

      product_id: '',
      price_id: '',
      subscription_id: '',
      subscription_status: '',
      subscription_period_start: '',
      subscription_period_end: '',
      subscription_canceled: false,
      payment_failed: null,

      company_vat: null,
      company_name: null,
      company_country: null,
      company_address: null,
      company_verified: null,
    }
  },

  actions: {

        async getQueries() {
          this.queries = (await User.getQueries()).data
        },

        async details() {
          let data = (await User.getDetails()).data

          this.accountType = data.type;
          this.product_id = data.product_id;
          this.price_id = data.price_id;
          this.subscription_id = data.subscription_id;
          this.subscription_status = data.subscription_status;
          this.subscription_period_start = data.subscription_start;
          this.subscription_period_end = data.subscription_end;
          this.subscription_canceled = data.subscription_canceled;
          this.payment_failed = data.payment_failed;

          this.company_vat = data.company_vat;
          this.company_name = data.company_name;
          this.company_country = data.company_country;
          this.company_address = data.company_address;
          this.company_verified = data.company_verified;

          this.limit_feed = data.limit_feed;
          this.limit_api_daily = data.limit_api_daily;
          this.limit_api_monthly = data.limit_api_monthly;

          this.api_daily_spend = data.api_daily_usage;
          this.api_monthly_spend = data.api_monthly_usage;
        },
        // we are storing the token in a cookie and thus not using the token stored in state.token,
        // which are passed automatically during each API call. 
        async login(email, password) {
            let data = await Auth.login(email, password)
            if (data.status == 1) {
                // commit("user/login")
                localStorage.setItem("token", data.token)
                localStorage.setItem("token_exp", data.exp)
                // initialize the user
                this.email = data.email; 
                this.accountType = data.type; 
                this.limit_feed = data.limit_feed;
                this.limit_api_daily = data.limit_api_daily;
                this.limit_api_monthly = data.limit_api_monthly;
                

                await this.init();
              }
            return data; 
        }, 
        
        async signup(email, password, name) {
          let { data } = await Auth.register(email, password, name)
          // if (data.status == "success") {
          //     commit("user/login")
          //     await dispatch("user/init")
          //     await dispatch("webscout/init")
          // }
          return data; 
        }, 
    
        async newsletter(email) {
          let { data } = await Auth.newsletter_signup(email)
          // if (data.status == "success") {
          //     commit("user/login")
          //     await dispatch("user/init")
          //     await dispatch("webscout/init")
          // }
          return data; 
        }, 
        
        async logout() {
            localStorage.removeItem("token")
            this.loggedIn = false; 
            return true; 
            
            /*
            let { data } = await Auth.logout(); 
            if (data.status == "logged out") {
              localStorage.removeItem("token")
              this.loggedIn = false; 
              return true; 
            }
            return false;
            */
        },
        
        async search(query) {
          return await Search.search(query)
        },
    
        async search_status(query) {
          return await Search.status(query)
        },

        async query_status(query_id) {
          return await Search.query_status(query_id)
        },

        async query_result(query_id) {
          return await Search.query_result(query_id)
        },

        async website(query_id) {
          return await Search.website(query_id)
        },

        async community_scans() {
          return await Search.community_scans()
        },

        async init() {
          let token = localStorage.getItem("token")
          let token_exp = localStorage.getItem("token_exp")
          let d = new Date(token_exp * 1000)
      
          if ( d.getTime() < Date.now()) {
            localStorage.removeItem("token")
            localStorage.removeItem("token_exp")
            token = ""
            return 
          }

          if (token) {
            let dt = jwtDecode(token) 
            this.token = token
            axios.defaults.headers.common["Authorization"] = `Bearer: ${this.token}`
            this.loggedIn = true; 
            this.email = dt.email; 
            this.name = dt.name;
            this.id = dt.id;
            this.accountType = dt.type;
            this.limit_feed = dt.limit_feed;
            this.limit_api_daily = dt.limit_api_daily;
            this.limit_api_monthly = dt.limit_api_monthly;

            await this.details()
          }

          /*
          let domainStore = useDomainStore()
          let monitorStore = useMonitorStore()
          let data = await User.getDetails();
          await domainStore.init();
          await monitorStore.init();
          */
        },
  }
})
