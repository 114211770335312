<script setup>
import { onMounted, ref, computed } from 'vue'
import Plans from '@/data/plans.js'
import Payment from '@/api/Payment.js'
import ContactModal from '@/components/modals/Contact.vue'

import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()

import { useUserStore } from '@/stores/user'
let userStore = useUserStore()

import PricingJumbo from '@/components/Pricing/PricingJumbo.vue'
import RenewNotice from '@/components/Pricing/RenewNotice.vue'
import FreeJumbo from '@/components/Pricing/FreeJumbo.vue'
import MultiSection from '@/components/Table/MultiSection.vue'


async function purchase_plan(plan = 'price_1QCfA1KWiwNNrZCKTWtNPYcf' ) {
  let session = await Payment.create_session(plan)
  window.location.href = session.url
}

async function change_subscription(price_id) {
  let session = await Payment.change_subscription(price_id)
  window.location.href = session.url
}

</script>
<template>
  <div id="wrapper-pricing" class="p-3">

    <div  class="container pt-5">
      <div class="d-flex column centered mb-5">
        <h2 class="sub-title title mb-3">
          Choose your plan
        </h2>
        <p class="follow-text m-0 enlarged text-center" >
    Get started with options that fit your needs. Predictable pricing.
        </p>
      </div>
    </div>
    <div class="d-flex centered column" >


      <div style="width: 1500px;">
        
        <RenewNotice v-if="userStore.subscription_canceled" class="mb-3"/>


        <PricingJumbo class="mb-3"/>
      </div>

    </div>

    <div  class="container mtt-8">
      <div class="d-flex column centered mb-5">
        <h3 class="sub-title mb-3" style="font-size: 2rem;">
          Compare our plans
        </h3>
        <p class="follow-text m-0 enlarged text-center" >
        and find the one that fits you best
        </p>
      </div>
    </div>

    <div class="container pt-5">
      <div>
        <MultiSection>
        </MultiSection>
      </div>
    </div>

  </div>
</template>
<style scoped>
.title {
  font-size: 2.5rem !important;
}

.sub-title {
  font-weight: bold;
  font-size: 2rem;
  display: inline-block;
  background: linear-gradient(90deg, #ffffffb8, white, #ffffffb8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.follow-text {
  color: white;
  font-weight: 300; 
}

.enlarged {
  font-size: 1.15rem;
  font-weight: 400;
  color: #7f8796;
}

button {
  background-color: #1E252F;
  border-radius: 5px;
  border:none;
  margin-right: 5px;
  padding: 0 10px;
  font-weight: bold;
  color: white;
  padding: .8rem 0px;
  width: 100%;
}

button.dark {
  background-color: #131820
}

button:hover {
  background-color: #2B3440;
}

button.disabled {
  background-color:  #171C24
}

button.disabled:hover {
  background-color:  #171C24;
  cursor: default;
}
</style>
