<script setup>
  import { ref } from 'vue'
  import Plans from '@/data/plans.js'
  
  let plan = Plans.filter( p => p.title == "Free")[0]
</script>

<template>
  <div id="pj">
    <div>
      <h5 class="title uppercase mb-3" :style="{'color': plan.color}">{{ plan.title }}</h5>
      <p class="caption">{{ plan.caption }}</p>
    </div>
    <div class="ms-auto">
      <router-link class="button tin text-center" to="/signup">Try it now!</router-link>
    </div>
  </div>
</template>

<style scoped>
#pj {
    background-color: #1D232C;
    border-radius: 10px;
    display: flex;
    padding: 2rem;
    align-items: center;
}

.price {
  font-size: 2rem;
  color: white;
  font-weight: 800;
  line-height: 2rem;
}

.caption {
  line-height: 1.3rem;
  margin-bottom: 0; 
}


.elem {
  flex: 1;
  padding: 2rem;
}

.elem:not(:last-child) {
  border-right: 1px solid #232a3582
}

.title {
  font-family: Roboto;
  font-weight: 600;
  color: white;
  font-size: 1rem;
}


.button {
  display: block;
  padding: .5rem 3.1rem;
  border-radius: 10px;
  background-color: #5094ed;
  color: white;

}

.button:hover {
  background-color: #4c82c8;
  cursor: pointer;
}

.button.black {
  background-color: #232A35;
}

.button.black:hover {
  background-color: #2F3744;
}

@media (max-width: 600px) {
  .tin {
    width: 120px;
    padding: .5rem 1.5rem;
    margin-left: 10px;
  } 
}
</style>
