import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue'
import SearchResult from '../views/SearchResult.vue'
//import Signup from '../views/Signup.vue'
import Signup from '../views/Signup.vue'
import Index from '../views/Index.vue'
import Search from '../views/Search.vue'
import Plans from '../views/Plans.vue'
import Pricing from '../views/Pricing.vue'

//import HomeSearch from '../views/Home.vue'
//import Blue from '../views/Authenticated/Blue.vue'
//import Domains from '../views/Authenticated/Domains.vue'
import About from '../views/About.vue'
import Tos from '../views/Tos.vue'
import Privacy from '../views/Privacy.vue'
import Datasets from '../views/Datasets.vue'
//import AdminHome from '../views/Home.vue'
import UserIndex from '../views/Authenticated/UserIndex.vue'
import DocWiki from '../views/Authenticated/Docs/Wiki/Index.vue'
import ThreatNews from '../views/Authenticated/ThreatNews.vue'
import Streams from '../views/Authenticated/Streams.vue'
import Stream from '../views/Authenticated/Stream/Stream.vue'
import Feeds from '../views/Authenticated/Feeds.vue'

import LandingPage from '../views/LandingPage.vue'


/////////////////// AUTHENTICATED /////////////////////////
import Dashboard from '../views/Authenticated/Dashboard.vue'

// QUERY VIEWS 
import Query from '../views/Authenticated/Query/Query.vue'
/*
import Query from '../views/Authenticated/Query/Index.vue'
import QueryDomain from '../views/Authenticated/Query/Domain.vue'
import QueryIP from '../views/Authenticated/Query/Ip.vue'
import QueryJarm from '../views/Authenticated/Query/Jarm.vue'
import QueryUrl from '../views/Authenticated/Query/Url.vue'
import QueryDashboard from '../views/Authenticated/Query/Dashboard.vue'
*/

// FEEDS 
import PublicFeeds from '../views/Feeds/Index.vue'

// ACCOUNT 
import AccountHome from '../views/Authenticated/Account/Home.vue'
import AccountInfo from '../views/Authenticated/Account/Information.vue'
import AccountApiKeys from '../views/Authenticated/Account/ApiKeys.vue'
import AccountBilling from '../views/Authenticated/Account/Billing.vue'

// DOCUMENTATION VIEWS
import DocsIndex from '../views/Authenticated/Docs/Index.vue'
import ApiReferenceAuthentication from '../views/Authenticated/Docs/Api/Pages/Authentication.vue'
import ApiReferenceFeeds from '../views/Authenticated/Docs/Api/Pages/Feeds.vue'
import ApiReferenceNews from '../views/Authenticated/Docs/Api/Pages/News.vue'
import ApiReferenceUsage from '../views/Authenticated/Docs/Api/Pages/Usage.vue'
import ApiReferenceIndex from '../views/Authenticated/Docs/Api/Index.vue'

import ApiReferenceAssetUrl from '../views/Authenticated/Docs/Api/Pages/Assets/Url.vue'
import ApiReferenceAssetArtifact from '../views/Authenticated/Docs/Api/Pages/Assets/Artifact.vue'
import ApiReferenceAssetDomain from '../views/Authenticated/Docs/Api/Pages/Assets/Domain.vue'
import ApiReferenceAssetIp from '../views/Authenticated/Docs/Api/Pages/Assets/Ip.vue'
import ApiReferenceAssetHash from '../views/Authenticated/Docs/Api/Pages/Assets/Hash.vue'



import WikiIntroduction from '../views/Authenticated/Docs/Wiki/Pages/Introduction.vue'
import WikiCaseAttackSurfaces from '../views/Authenticated/Docs/Wiki/Pages/CaseMappingAttackSurfaces.vue'
import WikiCaseAnonymization from '../views/Authenticated/Docs/Wiki/Pages/CaseDetectAnonymization.vue'





import { useUserStore } from '../stores/user'

const routes = [
  {
    path: '',
    name: 'Index',
    component: Index,
    meta: {
      guestOnly: true,
    },
    children: [
      {
        path: '',
        name: 'LandingPage',
        component: LandingPage,
      },
      {
        path: '/pricing',
        name: 'Pricing',
        component: Pricing,
      },
      {
        path: '/datasets',
        name: 'Datasets',
        component: Datasets,
      },
      {
        path: '/resources/termsofservice',
        name: 'TermsOfService',
        component: Tos,
      },
      {
        path: '/resources/privacy',
        name: 'Privacy',
        component: Privacy,
      },
      {
        path: '/about',
        name: 'About',
        component: About,
      },
      {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
          guestOnly: true,
        }
      },
      {
        path: '/signup',
        name: 'Signup',
        component: Signup,
        meta: {
          guestOnly: true,
        }
      },
     ]
  },
  {
    path: '',
    name: 'UserIndex',
    component: UserIndex,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: Dashboard,
      },
      /*
      {
        path: 'news',
        name: 'UserNews',
        component: ThreatNews,
      },
      {
        path: 'streams',
        name: 'UserStreams',
        component: Streams,
      },
      */
      {
        path: 'plans',
        name: 'Plans',
        component: Plans,
      },
      {
        path: 'query',
        name: 'UserQuery',
        component: Query,
        props: true
        /*
        children: [
          {
            path: "",
            component: QueryDashboard,
            name: "QueryDashboard"
          },
          {
            path: 'domain/:dom',
            component: QueryDomain,
          },
          {
            path: 'ip/:ip',
            component: QueryIP,
          },
          {
            path: 'jarm/:jarm',
            component: QueryJarm,
          },
          {
            path: 'url/:url(.+)',
            component: QueryUrl,
          },
        ],
        */
      },
      {
        path: 'feeds',
        name: 'UserFeeds',
        component: Feeds,
      },
      /*
      {
        path: 'stream',
        name: 'UserStream',
        component: Stream,
      },
      */
      {
        path: 'account',
        name: 'Account',
        component: AccountHome,
        children: [
          {
            path: '',
            name: 'AccountInfo',
            component: AccountInfo,
            children: []
          },
          {
            path: 'apikeys',
            name: 'AccountApiKeys',
            component: AccountApiKeys,
            children: []
          },
          {
            path: 'billing',
            name: 'AccountBilling',
            component: AccountBilling,
            children: []
          },
        ]
      },
      {
        path: 'docs',
        name: 'DocsIndex',
        component: DocsIndex,
        children: [
          {
            path: 'wiki',
            name: 'UserWiki',
            component: DocWiki,
            children: [
              {
                path: '',
                name: 'WikiIntroduction',
                component: WikiIntroduction,
              },
              {
                path: 'case/mapping-attack-surfaces',
                name: 'WikiCaseAttackSurfaces',
                component: WikiCaseAttackSurfaces,
              },
              {
                path: 'case/detect-anonymization',
                name: 'WikiCaseAnonymization',
                component: WikiCaseAnonymization,
              },

            ]
          },
          {
            path: 'api',
            name: 'UserApiReference',
            component: ApiReferenceIndex,
            children:[
              {
                path: 'feeds',
                name: 'ApiReferenceFeeds',
                component: ApiReferenceFeeds,
              },
              {
                path: 'usage',
                name: 'ApiReferenceUsage',
                component: ApiReferenceUsage,
              },
              {
                path: 'authentication',
                name: 'ApiReferenceAuthentication',
                component: ApiReferenceAuthentication,
              },
              {
                path: 'news',
                name: 'ApiReferenceNews',
                component: ApiReferenceNews,
              },

              {
                path: 'asset/url',
                name: 'ApiReferenceUrl',
                component: ApiReferenceAssetUrl,
              },
              {
                path: 'asset/hash',
                name: 'ApiReferenceHash',
                component: ApiReferenceAssetHash,
              },
              {
                path: 'asset/artifact',
                name: 'ApiReferenceArtifact',
                component: ApiReferenceAssetArtifact,
              },
              {
                path: 'asset/ip',
                name: 'ApiReferenceIp',
                component: ApiReferenceAssetIp,
              },
              {
                path: 'asset/domain',
                name: 'ApiReferenceDomain',
                component: ApiReferenceAssetDomain,
              },
            ]
          },
        ]
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  path: process.env.VUE_APP_PATH,
  routes
})

router.beforeEach( (to, from, next) => {
  let userStore = useUserStore()

  if(to.matched.some(record => record.meta.requiresAuth)) {
    // check if loggedIn 
    if (userStore.loggedIn) {

      // loggedIn
      return next()

    } else {

      // not logged in
      return next({
        name: 'Login',
        params: { redirect: to.path }
      })

    } 

  } else if(to.matched.some(record => record.meta.guestOnly)) {

    if (userStore.loggedIn) {
      return next({
        name: 'Dashboard',
      })
    } else {
      return next()
    }

  } else {
    // auth not required... proceed
    return next()
  }

}); 

export default router
