<script setup>
  import { useUserStore } from '@/stores/user'
  import { useRouter, useRoute} from 'vue-router'
  const userStore = useUserStore()
  import { useMainStore } from '@/stores/main'
  let mainStore = useMainStore()
  import Payment from '../../../api/Payment'

  import { onMounted,ref } from 'vue'
  import Slideover from '../../../components/SlideOver/slideover.vue'
  import CreateForm from '../../../components/SlideOver/forms/CreateApiKey.vue'
  import User from '../../../api/User';
  import moment from 'moment'
  import Card from '../../../components/Card.vue'

  async function customer_portal() {
    let session = await Payment.customer_portal("/account")
    window.location.href = session.url
  }

</script>

<template>
  <div>
    <div class="row mb-5"> <!-- start row -->
      <div class="col-md-6 mb-3">
        <div class="box padded">
          <span>Name</span>
          <h4 class="m-0">{{ userStore.name }}</h4>
        </div>
      </div>

      <div class="col-md-6 mb-3">
        <div class="box padded">
          <span>Email</span>
          <h4 class="m-0">{{ userStore.email }}</h4>
        </div>
      </div>

      <div class="col-md-6 mb-3">
        <div class="box padded">
          <span>Password</span>
          <h4 class="m-0">*****************</h4>
        </div>
      </div>
    </div> <!-- end row -->

    <div class="row">
      <div class="col-md-12 mb-3">
        <h5 style="color: #6D7785;">Business registration</h5>
      </div>

      <div class="col-md-6 mb-3">
        <div class="box padded">
          <span>Tax ID</span>
          <h4 class="m-0" v-if="userStore.company_vat">{{userStore.company_vat}}</h4>
          <h4 class="m-0" v-else>None</h4>

          <div class="trash-btn corner" @click="customer_portal()">
            <font-awesome-icon style="font-size: 16px;" :icon="['fas', 'pen-to-square']"/>
          </div>
        </div>
      </div>

      <div class="col-md-6 mb-3" v-if="userStore.company_name">
        <div class="box padded">
          <span>Company name</span>
          <h4 class="m-0">{{ userStore.company_name }}</h4>
        </div>
      </div>
      <div class="col-md-6 mb-3" v-if="userStore.company_address">
        <div class="box padded">
          <span>Company Address</span>
          <h4 class="m-0">{{ userStore.company_address }}</h4>
        </div>
      </div>
      <div class="col-md-6 mb-3" v-if="userStore.company_address">
        <div class="box padded">
          <span>Company Country</span>
          <h4 class="m-0">{{ userStore.company_country }}</h4>
        </div>
      </div>

    </div>

  </div>
</template>

<style scoped>
.corner {
  position: absolute;
  right: 2rem;
  top: 1.5rem; 
}
.trash-btn {
  color: rgb(109, 119, 133);
    padding: 0.5rem 0.8rem;
    border: 1px solid transparent;
    border-radius: 10px;
    transition: .2s all ease;
}

.trash-btn:hover {
  color: rgb(92 158 242);
  border-color: rgb(92 158 242);
  cursor: pointer;
}
</style>
