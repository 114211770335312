<script setup>
import { useUserStore } from '@/stores/user'
import { useRouter, useRoute} from 'vue-router'
const userStore = useUserStore()
import { useMainStore } from '@/stores/main'
let mainStore = useMainStore()
import Payment from '../../../api/Payment'

import { onMounted,ref } from 'vue'
import Slideover from '../../../components/SlideOver/slideover.vue'
import CreateForm from '../../../components/SlideOver/forms/CreateApiKey.vue'
import User from '../../../api/User';
import moment from 'moment'
import Card from '../../../components/Card.vue'

const keys = ref([])

const show_slideover = ref(false)
const slideover_component = ref(null)
const slideover_props = ref(null)


onMounted(async () => {
  keys.value = (await User.list_api_keys()).data;
})

  
async function close() {
  show_slideover.value = false
  keys.value = (await User.list_api_keys()).data;
} 

function show_create_key() {
  mainStore.show_slideover(CreateForm, { },)
}

async function click_handler(id) {
  await User.delete_api_key(id)
  keys.value = (await User.list_api_keys()).data;
}

mainStore.event_on("created_apikey", async () => {
  keys.value = (await User.list_api_keys()).data;
})

</script>

<template>
  <div>
    

    <!-- API KEYS --> 
    <div class="row mb-5">
      <div class="col-md-12">
        <div class="mb-3" style="max-height: 600px; overflow-y: auto;">
          <div v-if="keys.length == 0">You don't have any active API keys</div>
          <div class="box small-padded mb-2 d-flex" v-for="key in keys" :key="key">
              <div style="flex:1">
                <div><strong class="white">{{key.Name}}</strong></div>
                <div>{{key.Description}}</div>
              </div>
              <div class="d-flex vertically-centered">
                <div class="trash-btn" @click="click_handler(key.Id)">
                <font-awesome-icon style="font-size: 16px;" :icon="['fas', 'trash']"/>
                </div>
              </div>
          </div>
        </div>
        <div class="box dark med-padded clickable mt-2" style="display: inline-block"  @click="show_create_key()">
          <font-awesome-icon style="font-size: 14px;" class="me-2" :icon="['fas', 'plus']"/><strong>API Key</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  
.trash-btn {
    padding: 0.5rem 0.8rem;
    border: 1px solid transparent;
    border-radius: 10px;
    transition: .2s all ease;
}

.trash-btn:hover {
  color: rgb(242 92 92);
  border-color: rgb(242 92 92);
  cursor: pointer;
}
</style>
