<script setup>
import {defineProps, defineEmits, ref, onMounted, computed} from 'vue'
import User from '../../../api/User';
import moment from 'moment'
import { useMainStore } from '@/stores/main'
let mainStore = useMainStore()

const processing = ref(false)

const created = ref(false)
const failed = ref(false)
const key_value = ref("")

const key_name = ref("")
const key_description = ref("")

async function submit() {
  processing.value = true;
  created.value = false;
  
  let data = await User.create_api_key(key_name.value, key_description.value)
  if (data.status == "success") {
    created.value = true 
    key_value.value = data.key.Key

    mainStore.event_emit("created_apikey")
  } else {
    failed.value = true
  }

  processing.value = false;
}

</script>

<template>
  <div style="padding: 2rem;">
    <div class="d-flex column" style="height: 100%;">
      <h4>Create API Key</h4>
      <hr>

      <form id="form1" @submit.prevent="submit()">
        <div class="form-floating mb-3">
          <input type="text" v-model="key_name" class="form-control" id="floatingInput" placeholder="name@example.com">
          <label for="floatingInput">Name</label>
        </div>
        <div class="form-floating mb-3">
          <textarea class="form-control" v-model="key_description" id="floatingPassword" placeholder="Password" style="height: 300px;" resi></textarea>
          <label for="floatingPassword">Description</label>
        </div>
      </form>
    
      <div v-if="created">
        <hr>
        <h5>API Key successfully created</h5>
        Your API Key is: <strong class="white">{{key_value}}</strong>
        <br><br>
        Please save it in a secure place, as you will not be able to see it again.
      </div>
      <div v-else-if="failed">
        <hr>
        Failed to create an API KEY. 
        <br>Please try a different name and description, and ensure they only contain ASCII characters
      </div>
      <div class="mt-auto d-flex" v-if="!created" style="flex-direction: row-reverse">
          <button type="submit" form="form1" class="btn btn-outline-success me-2" :disabled="processing">Create</button>
      </div>

    </div>
  </div>
</template>

<style scoped>
.btn {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check:checked + .btn {
  color: white !important;
  background: transparent;
  outline: white;
}
</style>
