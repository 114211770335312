import axios from 'axios';
import util from '../lib/util';


const API_URL = process.env.VUE_APP_API_URL;
const BASE_URL = process.env.VUE_APP_BASE;
const STRIPE_TOKEN = process.env.VUE_APP_STRIPE;

class Payment {

    async create_session(priceId) {
        try {
            const { data } =  await axios.post(API_URL + '/checkout/create-session', {
                price_id:    priceId,
            });
            return data; // contains the checkout session
        } catch(err) {
            throw "App error. Please try again later" 
        }
    }

    async change_subscription(priceId) {
        try {
            const { data } =  await axios.post(API_URL + '/checkout/change', {
                price_id:    priceId,
            });
            return data; // contains the checkout session
        } catch(err) {
            throw "App error. Please try again later" 
        }
    }

    async payment_method() {
        try {
            const { data } =  await axios.post(API_URL + '/checkout/payment_method');
            return data; // contains the checkout session
        } catch(err) {
            throw "App error. Please try again later" 
        }
    }

    async list_invoices() {
        try {
            const { data } =  await axios.post(API_URL + '/checkout/invoices');
            return data; // contains the checkout session
        } catch(err) {
            throw "App error. Please try again later" 
        }
    }

    async customer_portal(redirect_to) {
        try {
            let b = {}
            if (redirect_to != undefined) b["redirect_to"] = redirect_to
            const { data } =  await axios.post(API_URL + '/checkout/billing', b);
            return data; // contains the checkout session
        } catch(err) {
            throw err 
        }
    }

    async cancel_subscription() {
        try {
            const { data } =  await axios.post(API_URL + '/checkout/cancel');
            return data; // contains the checkout session
        } catch(err) {
            throw "App error. Please try again later" 
        }
    }

    async renew_subscription() {
        try {
            const { data } =  await axios.post(API_URL + '/checkout/renew');
            return data; // contains the checkout session
        } catch(err) {
            throw "App error. Please try again later" 
        }
    }

    async update_paymentinfo() {
        try {
            const { data } =  await axios.post(API_URL + '/checkout/update_paymentinfo');
            return data; // contains the checkout session
        } catch(err) {
            throw "App error. Please try again later" 
        }
    }

}

export default new Payment();
