<script setup>
import { useUserStore } from '@/stores/user'
import { useRouter, useRoute} from 'vue-router'
const userStore = useUserStore()
import { useMainStore } from '@/stores/main'
let mainStore = useMainStore()
import Payment from '../../../api/Payment'
import PlanAction from '@/components/Pricing/PlanAction.vue'

import { onMounted,ref } from 'vue'
import Slideover from '../../../components/SlideOver/slideover.vue'
import CreateForm from '../../../components/SlideOver/forms/CreateApiKey.vue'
import User from '../../../api/User';
import moment from 'moment'
import Card from '../../../components/Card.vue'

const invoices = ref([])
const card = ref(null)

onMounted(async () => {
  invoices.value = (await Payment.list_invoices()).invoices;
  card.value = (await Payment.payment_method()).data;
})

let product_name = {
  // test
  "prod_R4onB3hCR7Nqdd": "Basic",
  "prod_R4s3kLpgm2E26Q": "Professional",
  "prod_RcWJghIVtoTZMy": "Elite",

  // production
  "prod_RgksNDGBRJv9tw": "Basic",
  "prod_Rgksz4gmBvlxgf": "Professional",
  "prod_RgkshjtyBjFDKV": "Elite",
}

</script>

<template>
  <div>
          <!-- Add subscription plan card -->
      <div class="mb-5">
        <div class="col-md-12">
          <PlanAction/>
        </div>
      </div>


      <!-- Invoices --> 
      <div class="row">
        <div class="col-md-12">
          <div class="col-md-12 mb-3">
            <h5 style="color: #6D7785;">Payment history</h5>
          </div>
            <div style="max-height: 600px; overflow-y: auto;">

              <div v-if="invoices.length == 0">You haven't made any payments yet</div>
              <div class="box small-padded mb-2 d-flex" v-for="invoice in invoices" :key="invoice">
                  <div class="col-md-6" style="flex:1">
                    <div>
                      <strong class="white" v-if="product_name[invoice.product_id] != undefined">{{ product_name[invoice.product_id] }}</strong>
                      <strong class="white" v-else>{{ invoice.product_id }}</strong>


                      <span class="small ms-2" v-if="invoice.reason == 'subscription_update'">(change)</span>
                      <span class="small ms-2" v-else-if="invoice.reason == 'subscription_create'">(purchase)</span>
                      <span class="small md-2" v-else-if="invoice.reason == 'subscription_cycle'">(renewal)</span>
                    </div>
                    <div>{{moment(invoice.created).format('LL')}}</div>
                  </div>
                  <div class="me-3 d-flex vertically-centered white">
                    ${{ invoice.amount / 100 }}
                  </div>
                  <div class="d-flex vertically-centered">
                    <a class="button black mt-auto" :href="invoice.link" download target="_blank">
                      <div class="trash-btn">
                        <font-awesome-icon style="font-size: 16px;" :icon="['fas', 'download']"/>
                      </div>
                    </a>
                  </div>
              </div>
            </div>

            <!--
            <div class="box dark med-padded clickable mt-2" style="display: inline-block"  @click="show_create_key()">
              <font-awesome-icon style="font-size: 14px;" class="me-2" :icon="['fas', 'plus']"/><strong>API Key</strong>
            </div>
            -->
        </div>
      </div>
  </div>
</template>

<style scoped>
  
  
.trash-btn {
  color: rgb(109, 119, 133);
    padding: 0.5rem 0.8rem;
    border: 1px solid transparent;
    border-radius: 10px;
    transition: .2s all ease;
}

.trash-btn:hover {
  color: rgb(92 158 242);
  border-color: rgb(92 158 242);
  cursor: pointer;
}
</style>
