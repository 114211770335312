<script setup>
import { onMounted, ref, computed } from 'vue'
import Feeds from '../../api/Feeds';
import Slideover from '../../components/SlideOver/slideover.vue'
import FeedComp from '../../components/SlideOver/components/Feed.vue'
import FeedForm from '../../components/SlideOver/forms/CreateFeed.vue'
import { useUserStore } from '@/stores/user'
import moment from 'moment'
import { useMainStore } from '@/stores/main'
let mainStore = useMainStore()

import ContactModal from '@/components/modals/Contact.vue'
import Datastore from '@/api/Datastore.js'

import Card from '@/components/Card.vue'
import DatasetWidget from '@/components/DatasetWidget.vue'

const userStore = useUserStore()
const datasets = ref([])
const API_URL = process.env.VUE_APP_API_URL;

const format = ref("")

onMounted(async () => {
  datasets.value = (await Datastore.list()).data;
  console.log(datasets.value)
  //feeds.value = (await Feeds.featured()).data
})

const owned_datasets = computed( () => {
  let result = []; 
  for(let i = 0; i<datasets.value.length; i++) {
    if(datasets.value[i].has_active_license) {
      result.push(datasets.value[i])
    }
  }
  return result
})

const ip_datasets = computed( () => {
  let result = []; 
  for(let i = 0; i<datasets.value.length; i++) {
    if(datasets.value[i].has_active_license) {
      continue
    }
    if(datasets.value[i].category == "Ip") {
      result.push(datasets.value[i])
    }
  }
  return result
})

const domain_datasets = computed( () => {
  let result = []; 
  for(let i = 0; i<datasets.value.length; i++) {
    if(datasets.value[i].has_active_license) {
      continue
    }
    if(datasets.value[i].category == "Domain") {
      result.push(datasets.value[i])
    }
  }
  return result
})

const blocklist_datasets = computed( () => {
  let result = []; 
  for(let i = 0; i<datasets.value.length; i++) {
    if(datasets.value[i].has_active_license) {
      continue
    }
    if(datasets.value[i].category == "Blocklists") {
      result.push(datasets.value[i])
    }
  }
  return result
})



</script>

<template>
  <div style="min-height: 100%;" class="p-3">
    
    <div class="mb-3" v-if="owned_datasets.length > 0">
      <div class="box mb-3 med-padded d-flex vertically-centered">
        <h5 class="mb-0">Owned</h5>
      </div>
     
      <div class="d-flex" style="flex-wrap: wrap;">
        <Card v-for="dataset in owned_datasets" class="me-3 mb-3" :key="dataset" >
          <DatasetWidget :dataset="dataset" style="height: 100%;"/> 
        </Card>
      </div>
    </div>

    <div class="mb-3" v-if="blocklist_datasets.length > 0">
      <div class="box mb-3 med-padded d-flex vertically-centered">
        <h5 class="mb-0">Blocklists</h5>
      </div>
     
      <div class="d-flex" style="flex-wrap: wrap;">
        <Card v-for="dataset in blocklist_datasets" class="me-3 mb-3" :key="dataset" >
          <DatasetWidget :dataset="dataset" style="height: 100%;"/> 
        </Card>
      </div>
    </div>

    <div v-if="ip_datasets.length > 0">
      <div class="box mb-3 med-padded d-flex vertically-centered">
        <h5 class="mb-0">Ip datasets</h5>
      </div>
     
      <div class="d-flex" style="flex-wrap: wrap;">
        <Card v-for="dataset in ip_datasets" class="me-3 mb-3" :key="dataset" >
          <DatasetWidget :dataset="dataset" style="height: 100%;"/> 
        </Card>
      </div>
    </div>

    <div v-if="domain_datasets.length > 0">
      <div class="box mb-3 med-padded d-flex vertically-centered">
        <h5 class="mb-0">Domain datasets</h5>
      </div>
     
      <div class="d-flex" style="flex-wrap: wrap;">
        <Card v-for="dataset in domain_datasets" class="me-3 mb-3" :key="dataset" >
          <DatasetWidget :dataset="dataset" style="height: 100%;"/> 
        </Card>
      </div>
    </div>

    <!--
    <div class="mb-5">
      <div class="box mb-3 med-padded d-flex vertically-centered">
        <font-awesome-icon style="color: #e7f25c; font-size: 1.2rem;" class="me-4" :icon="['fas', 'square-rss']" />
        <h5 class="mb-0">Streams</h5>
      </div>
      
      <div class="d-flex">
        <div v-for="stream in Streams" class="me-3" :key="stream">
          <Stream :data="stream"/>
        </div> 
      </div>
    </div>

    <div class="">
      <div class="box mb-3 med-padded d-flex vertically-centered">
        <font-awesome-icon style="color: rgb(146, 139, 232); font-size: 1.2rem;" class="me-4" :icon="['fas', 'database']" />
        <h5 class="mb-0">Datasets</h5>
      </div>
     
      <div class="d-flex">
        <div v-for="database in Databases" class="me-3" :key="database">
          <Database :data="database"/>
        </div> 
      </div>
    </div>
    -->

  </div>
</template>

<style scoped>
</style>
