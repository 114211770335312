<script setup>
import { useMainStore } from '@/stores/main'
const mainStore = useMainStore()

import PricingJumbo from '@/components/Pricing/PricingJumbo.vue'
import FreeJumbo from '@/components/Pricing/FreeJumbo.vue'
import MultiSection from '@/components/Table/MultiSection.vue'

</script>

<template>
<div class="pb-5">
  <div  class="container pt-5">
    <div class="d-flex column centered mb-5">
      <h2 class="sub-title title mb-3">
        Choose your plan
      </h2>
      <p class="follow-text m-0 enlarged text-center" >
  Get started with options that fit your needs. Predictable pricing.
      </p>
    </div>
  </div>
  <div class="d-flex centered column" >
    <div class="pj-container">
      <PricingJumbo class="mb-3"/>

      <FreeJumbo/>
    </div>

  </div>

  <div  class="container mtt-8">
    <div class="d-flex column centered mb-5">
      <h3 class="sub-title mb-3" style="font-size: 2rem;">
        Compare our plans
      </h3>
      <p class="follow-text m-0 enlarged text-center" >
      and find the one that fits you best
      </p>
    </div>
  </div>

  <div class="container pt-5">
    <div>
      <MultiSection>
      </MultiSection>
    </div>
  </div>

</div>

</template>

<style scoped>

.title {
  font-size: 2.5rem !important;
}

.sub-title {
  font-weight: bold;
  font-size: 2rem;
  display: inline-block;
  background: linear-gradient(90deg, #ffffffb8, white, #ffffffb8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.follow-text {
  color: white;
  font-weight: 300; 
}

.enlarged {
  font-size: 1.15rem;
  font-weight: 400;
  color: #7f8796;
}

.pj-container {
  width: 1500px;
}

@media (max-width: 600px) {
  .pj-container {
    width: 100%;
  } 
}
</style>
